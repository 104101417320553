// extracted by mini-css-extract-plugin
export var SliderWidth = "Success-module--SliderWidth--d3b88";
export var Trust = "Success-module--Trust--bb1a6";
export var blueText = "Success-module--blueText--2706a";
export var clientContent = "Success-module--clientContent--e829e";
export var coma = "Success-module--coma--1b71b";
export var content = "Success-module--content--3c932";
export var customModal = "Success-module--customModal--1a908";
export var dec = "Success-module--dec--c15aa";
export var designation = "Success-module--designation--fdfbf";
export var dot = "Success-module--dot--45f1b";
export var heading = "Success-module--heading--84ec9";
export var iconContainer = "Success-module--iconContainer--071a8";
export var iconContainerLeft = "Success-module--iconContainerLeft--e4df7";
export var logos = "Success-module--logos--299a7";
export var logosBg = "Success-module--logosBg--bb9eb";
export var name = "Success-module--name--9b4ee";
export var playBtn = "Success-module--playBtn--2d3b8";
export var portfolioArrowIcon = "Success-module--portfolioArrowIcon--d8fea";
export var portfolioArrowIconCover = "Success-module--portfolioArrowIconCover--0d4f3";
export var portfolioArrowRightIconCover = "Success-module--portfolioArrowRightIconCover--d2795";
export var view = "Success-module--view--9200a";