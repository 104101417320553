// extracted by mini-css-extract-plugin
export var SliderWidth = "RescueTeam-module--SliderWidth--d7666";
export var Trust = "RescueTeam-module--Trust--d7787";
export var arrowAnimation = "RescueTeam-module--arrowAnimation--77434";
export var card = "RescueTeam-module--card--2b0d2";
export var cardDec = "RescueTeam-module--cardDec--8e6b5";
export var concerns = "RescueTeam-module--concerns--67c35";
export var content = "RescueTeam-module--content--e1a48";
export var description = "RescueTeam-module--description--8afcf";
export var heading = "RescueTeam-module--heading--87f4a";
export var iconContainer = "RescueTeam-module--iconContainer--3313a";
export var iconContainerLeft = "RescueTeam-module--iconContainerLeft--e58a5";
export var newBtn = "RescueTeam-module--newBtn--c8a41";
export var portfolioArrowIcon = "RescueTeam-module--portfolioArrowIcon--68b82";
export var portfolioArrowIconCover = "RescueTeam-module--portfolioArrowIconCover--dd1a3";
export var portfolioArrowRightIconCover = "RescueTeam-module--portfolioArrowRightIconCover--76728";
export var talkBtn = "RescueTeam-module--talkBtn--e5b85";