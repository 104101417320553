// extracted by mini-css-extract-plugin
export var VerticalTimeline = "Features-module--VerticalTimeline--bbf8d";
export var cardImg = "Features-module--cardImg--7d8b4";
export var description = "Features-module--description--2ac62";
export var even = "Features-module--even--4e841";
export var head = "Features-module--head--86698";
export var heading = "Features-module--heading--ad274";
export var mainDes = "Features-module--mainDes--801f4";
export var odd = "Features-module--odd--51e02";
export var onDemandTimeLine = "Features-module--onDemandTimeLine--b4523";
export var verticalElement = "Features-module--verticalElement--8813f";