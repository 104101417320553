import React, { useState, useEffect } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component"
import "react-vertical-timeline-component/style.min.css"
import * as styles from "./Features.module.scss"
import "./feature.scss"

const Features = ({ strapiData, featuresDec }) => {
  const [activeCard, setActiveCard] = useState(0)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [activeCard])

  const handleScroll = () => {
    const timelineElements = document.querySelectorAll(
      ".vertical-timeline-element--work"
    )

    timelineElements.forEach((element, index) => {
      const rect = element.getBoundingClientRect()

      if (
        rect.top <= window.innerHeight / 2 &&
        rect.bottom >= window.innerHeight / 2
      ) {
        setActiveCard(index)
      }
    })
  }

  const renderIconStyle = i => {
    const isActive = i === activeCard
    const borderColor = isActive ? "#2176FF" : "black"
    let marginTop = "110px"

    if (i === strapiData.cards.length - 1) {
      marginTop = "130px"
    }

    return {
      // background: "#2176FF",
      border: `10px solid ${borderColor}`,
      borderRadius: "50%",
      width: "20px",
      height: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginLeft: "-10px",
      marginTop: marginTop,
    }
  }

  const renderIcon = i => {
    const isActive = i === activeCard
    const iconStyle = {
      color: isActive ? "#2176FF" : "black",
    }

    return <div style={iconStyle} />
  }

  return (
    <div className={`${styles.onDemandTimeLine} Product`}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <p className={styles.description}>
          These are some of the major reasons why you need software project
          rescue ASAP!
        </p>
        <Row>
          <VerticalTimeline className={`${styles.VerticalTimeline} feature`}>
            {strapiData?.cards?.map((e, i) => (
              <VerticalTimelineElement
                key={i}
                className={`vertical-timeline-element--work ${styles.verticalElement}`}
                contentArrowStyle={{ borderRight: "0px solid #2176FF" }}
                iconStyle={renderIconStyle(i)}
                icon={renderIcon(i)}
              >
                <div
                  className={`text-start ${i % 2 === 0 ? "even" : "odd"}`}
                  key={i}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: i % 2 === 0 ? "flex-start" : "flex-end", // Switched alignment
                  }}
                >
                  <lottie-player
                    className={styles.cardImg}
                    autoplay
                    loop
                    src={e?.image1[0]?.localFile?.publicURL}
                    style={{
                      height: "60px",
                      width: "60px",
                    }}
                  />

                  <h3
                    className={styles.head}
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                  <p
                    className={styles.mainDes}
                    dangerouslySetInnerHTML={{
                      __html: e?.description?.description,
                    }}
                  />
                </div>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </Row>
      </Container>
    </div>
  )
}

export default Features
