// extracted by mini-css-extract-plugin
export var Heading = "Banner-module--Heading--7dc23";
export var arrowAnimation = "Banner-module--arrowAnimation--095fe";
export var bannerHeading = "Banner-module--bannerHeading--815be";
export var blueButton = "Banner-module--blueButton--63859";
export var cardWidth = "Banner-module--cardWidth--ca7b4";
export var cards = "Banner-module--cards--84085";
export var curve = "Banner-module--curve--8d558";
export var customeBtn = "Banner-module--customeBtn--648ec";
export var portfolioAppWorkBanner = "Banner-module--portfolioAppWorkBanner--d54ea";
export var subTitle = "Banner-module--subTitle--20a6b";
export var talent = "Banner-module--talent--8b937";
export var text = "Banner-module--text--90d5c";
export var tick = "Banner-module--tick--f9fa9";
export var vueJsCards = "Banner-module--vueJsCards--35061";