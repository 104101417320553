import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./Bets.module.scss"

function Bets({ strapiData }) {
  return (
    <div className={styles.bets}>
      <Container>
        <h1
          className={`${styles.heading} `}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <p
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className="gap-30">
          {strapiData?.cards?.map((e, i) => (
            <Col lg={4} className="gap-30">
              <div className={styles.card}>
                <img src={e?.image1[0]?.localFile?.publicURL} alt="tick" />
                <h3
                  dangerouslySetInnerHTML={{
                    __html: e?.subTitle,
                  }}
                />

                <p
                  className={styles.mainDes}
                  dangerouslySetInnerHTML={{
                    __html: e?.description?.description,
                  }}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Bets
