import { graphql,Script } from "gatsby"
import React from "react"
import ContactSales from "../../components/common/ContactSales"
import SEORevamp from "../../components/common/SEO_Revamp"
import MainLayout from "../../layouts/MainLayout"
import Banner from "../../components/project-rescue-services/Banner"
import Features from "../../components/project-rescue-services/Features"
import FeatureMobile from "../../components/project-rescue-services/FeatureMobile"
import Bets from "../../components/project-rescue-services/Bets"
import Success from "../../components/project-rescue-services/Success"
import Engagement from "../../components/project-rescue-services/Engagement"
import Emergency from "../../components/project-rescue-services/Emergency"
import RescueTeam from "../../components/project-rescue-services/RescueTeam"
import Faqs from "../../components/common/Faqs2"

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  return (
    <>
     <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="idle"
      />
      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        image="https://invozone-backend.s3.amazonaws.com/Portfolio_059cb9635f.png"
      />
    </>
  )
}
const projectRescue = ({ data }) => {
  // console.log(data)
  const heroSection = data?.strapiPage?.sections[0]
  const bets = data?.strapiPage?.sections[1]
  const feature = data?.strapiPage?.sections[2]
  const emergency = data?.strapiPage?.sections[3]
  const team = data?.strapiPage?.sections[4]
  const engagement = data?.strapiPage?.sections[5]
  const faqs = data?.strapiPage?.sections[6]

  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 1280) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 1280)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  return (
    <MainLayout bgChanged={false}>
      <Banner strapiData={heroSection} freshprep={true} />
      <Bets strapiData={bets}/>
      {!isMobile && !isTablet ? (
        <Features strapiData={feature} />
      ) : (
        <FeatureMobile strapiData={feature} />
      )}
      <Emergency strapiData={emergency}/>
      <RescueTeam strapiData={team}/>
      <Success/>
      <Engagement strapiData={engagement}/>
      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#F8F8F8" />
    </MainLayout>
  )
}

export const query = graphql`
  query projectRescue {
    strapiPage(slug: { eq: "software-project-rescue-services-2" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          subTitle2
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
            url
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default projectRescue
