import React from "react"
import { Col } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"

const Banner = ({ strapiData }) => {
  // const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={`${styles.portfolioAppWorkBanner}`}>
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col lg={6}>
            <div className={styles.Heading}>
              <h1
                className={styles.subTitle}
                dangerouslySetInnerHTML={{ __html: strapiData?.title }}
              ></h1>
              <p
                className={`${styles.bannerHeading} `}
                dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              ></p>
            </div>
            <div className={`${styles.cards} `}>
              {strapiData?.cards &&
                strapiData?.cards?.map((item, index) => (
                  <div className={`${styles.talent} `} key={index}>
                    <img
                      className={styles.tick}
                      src="https://invozone-backend.s3.us-east-1.amazonaws.com/arrow_4839ddd6a1.svg"
                      alt={item?.title}
                      style={{
                        height: "27px",
                        width: "27px",
                      }}
                    />
                    <div className={styles.text}>
                      <p>{item?.title}</p>
                    </div>
                  </div>
                ))}
            </div>
            <Link to={strapiData?.buttons[0]?.url}>
            <div className={`${styles.blueButton} `}>
              {strapiData?.buttons[0] && (
               
                  <p>Got An Idea</p>
            
              )}
            </div>
            </Link>
          </Col>
          <Col lg={6}>
            <img
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/Hero_Section_01_NEW_cb7f550db2.png"
              }
              decoding="async"
            />
          </Col>
        </Row>
      </Container>
      <img
        className={styles.curve}
        src="https://invozone-backend.s3.us-east-1.amazonaws.com/line_vector_c415196118.png"
      />
    </div>
  )
}

export default Banner
